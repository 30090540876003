import Register from "./components/Register";
// import logo from "./logo.svg";
import "./App.css";
import LoginForm from "./components/LoginForm";

function App() {
	return (
		<div className="container1">
			<LoginForm />
			{/* <Register /> */}
		</div>
	);
}

export default App;
