import { initializeApp } from "@firebase/app";
import {
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
	signOut,
} from "@firebase/auth";
import {
	addDoc,
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	deleteDoc,
	doc,
	getFirestore,
	getDocs,
	setDoc,
	updateDoc,
} from "@firebase/firestore";

import { firebaseConfig } from "../../../config/firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export const signIn = async (email, password) => {
	const result = { statusResponse: false, error: null };
	return signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const nameCollection = "users";
			const key = "uid";
			const value = userCredential.user.uid;
			return askDataKeyValue(nameCollection, key, value).then((res) => {
				return res;
			});
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			result.error = errorMessage;
			return result;
		});
};

export const askDataKeyValue = async (nameCollection, key, value) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(collection(db, nameCollection), where(key, "==", value));
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});
	return result;
};
