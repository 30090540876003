import React, { useState } from "react";
import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";
import { firebaseConfig } from "../config/firebaseConfig";
import "../styles/LoginForm.css";
import Loader from "./Loader";
import imagen from "./images/logoBPWhorizontal.png";
import { signIn } from "../utils/actions/firebase/firebaseActions";
import Register from "./Register";

function LoginForm() {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	// const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [mail, setMail] = useState("");
	const [contrasena, setContrasena] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [usuario, setUsuario] = useState({
		email: "",
		company: "",
		group: "",
		id: "",
		name: "",
		agency: "",
		type: "",
	});

	const doLogin = async (e) => {
		e.preventDefault();

		setLoading(true);
		const result = await signIn(mail, contrasena);

		console.log("**** result:", result);

		if (!result.statusResponse) {
			setLoading(false);
			setMail("");
			setContrasena("");
			alert(result.error);
		} else {
			localStorage.setItem("userLocal", JSON.stringify(result.data[0]));
			localStorage.setItem("usuariosEmpresa", result.data[0].company);
			setUsuario(result.data[0]);
			setLoading(false);
		}
	};

	return (
		<div>
			{usuario.type === "" ? (
				<div>
					<div className="titulo">
						<img src={imagen} className="icono" />
					</div>

					<div className="container2">
						<h2 className="titleLogin">Por favor autenticarse...</h2>
						<div className="login">
							<article className="fondo">
								<form onSubmit={(e) => doLogin(e)}>
									{loading && <Loader />}
									<input
										type="email"
										className="form-control mb-2"
										placeholder="Ingresa tu email..."
										onChange={(text) => setMail(text.target.value)}
										value={mail}
									/>
									<input
										type="password"
										className="form-control mb-2"
										placeholder="Ingresa tu contraseña..."
										onChange={(text) => setContrasena(text.target.value)}
										value={contrasena}
									/>

									<button className="buttonLogin">Iniciar Sesión</button>
								</form>
								{/* {usuario.type === "1" ? (
              // <Redirect to="/accounts" />
							<Redirect to="/super_administrator" />
              ) : usuario.type === "2" ? (
                // <Redirect to="/process-list" />
                <Redirect to="/administrator_company" />
                ) : usuario.type === "3" ? (
                  // <Redirect to="/supervisor-company/reg_procesos" />
                  <Redirect to="/supervisor_company" />
                  ) : (
                    usuario.type === "4" && <Redirect to="/worker-company" />
                  )} */}
							</article>
						</div>
					</div>
				</div>
			) : (
				<Register />
				// console.log("está autenticado")
			)}
		</div>
	);
}
export default LoginForm;
