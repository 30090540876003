import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import { createUserWithEmailAndPassword, getAuth } from "@firebase/auth";
import {
	addDoc,
	getFirestore,
	collection,
	query,
	orderBy,
	getDocs,
	doc,
	updateDoc,
} from "@firebase/firestore";
import "../styles/register.css";
import Loader from "./Loader";
import { firebaseConfig } from "../config/firebaseConfig";

function Register() {
	const initialForm = {
		email: "",
		company: "",
		password: "",
	};

	let user = {
		uid: "",
		name: "",
		email: "",
		type: "",
		company: "",
		agency: "",
		group: "",
		area: "",
	};
	let initialUser = user;
	const [form, setForm] = useState(initialForm);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [data, setData] = useState(null); //listado de empresas
	// const [user, setUser] = useState(initialUser);
	const [refresh, setRefresh] = useState(false);
	// const [selectedCompany, setselectedCompany] = useState("");
	// let selectedCompany = "";

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const auth = getAuth();

	const askAccounts = async () => {
		setLoading(true);
		// const respuesta = await askCuentas();
		const nameCollection = "accounts";
		const q = query(collection(db, nameCollection), orderBy("pos", "asc"));
		await getDocs(q)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setData(dataList);
			})
			.catch((error) => {
				console.log("error:", error);
				setError("no pudo leer la DB Empresas");
			});
		setLoading(false);
	};

	useEffect(() => {
		askAccounts();
	}, [refresh]);

	const validateData = (user) => {
		let isValid = true;
		if (form.company === "Seleccionar") {
			setError("Faltó seleccionar Empresa");
			isValid = false;
		}
		if (form.company === "") {
			setError("Faltó seleccionar Empresa");
			isValid = false;
		}
		return isValid;
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const doLogin = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (!validateData(form)) {
			setLoading(false);
			return;
		}
		await createUserWithEmailAndPassword(auth, form.email, form.password)
			.then((userCredential) => {
				user.uid = userCredential.user.uid;
				user.email = form.email;
				user.company = form.company;
				addDoc(collection(db, "users"), user).then(async (response) => {
					if (response.id) {
						const docRef = doc(db, "users", response.id);
						await updateDoc(docRef, {
							id: response.id,
						});
					}
					let mesageConfirm = window.confirm(`Se ha grabado el usuario`);
					if (mesageConfirm) {
						return;
					} else {
						return;
					}
				});
				user.uid = "";
				user.email = "";
				user.company = "";
				setError(null);
			})
			.catch((error) => {
				const errorMessage = error.message;
				setError(errorMessage);
			});

		handleReset();
		setLoading(false);
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	return (
		<div className="container2">
			<h2 className="registerTitle">Formulario de Registro</h2>
			<div className="login">
				<article className="fondo">
					<form onSubmit={(e) => doLogin(e)}>
						{loading && <Loader />}
						{error && <span className="textError">{error}</span>}

						<input
							type="email"
							name="email"
							className="inputFormat"
							placeholder="Ingresa email..."
							// onChange= {e => onChange(e, 'email')}
							// onChange={(text) => setEmail(text.target.value)}
							value={form.email}
							onChange={handleChange}
						/>
						<input
							type="password"
							name="password"
							className="inputFormat"
							placeholder="Ingresa tu contraseña..."
							// onChange= {e => onChange(e, 'email')}
							// onChange={(text) => setPassword(text.target.value)}
							value={form.password}
							onChange={handleChange}
						/>
						<label className="labelEmpresa">Empresa</label>
						<select
							className="inputFormat"
							name="company"
							onChange={handleChange}
							value={form.company}
						>
							<option selected>Seleccionar</option>
							{data &&
								data.map((el) => <option key={el.id}>{el.company}</option>)}
						</select>
						<input
							type="submit"
							value="HACER REGISTRO"
							className="btn-form-cuentas"
						/>
					</form>
				</article>
			</div>
		</div>
	);
}

export default Register;
